import React from "react";
import { Link } from "gatsby";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { useInView } from "react-cool-inview";

const BlogClankyItem = (props) => {
  const { observe, inView } = useInView({
    unobserveOnEnter: true,
    threshold: 0.3,
  });

  var url = props.clanok.slug;
  //url = url.toLowerCase();

  //console.log(props.clanok);

  return (
    <div className={"blog " + (inView ? "show" : "")} ref={observe}>
      <div>
        <Link to={"/blog/" + url} hrefLang="sk">
          {/*  <GatsbyImage className="img" image={props.clanok.featuredImage.node.gatsbyImage} alt={props.clanok.featuredImage.node.altText} title={props.clanok.featuredImage.node.title} placeholder="blurred" loading="lazy" />*/}
          <img
            className="img"
            src={props.clanok.featuredImage.node.sourceUrl}
            alt={props.clanok.featuredImage.node.altText}
            title={props.clanok.featuredImage.node.title}
            loading="lazy"
          />
        </Link>
      </div>
      <div>
        <Link to={"/blog/" + url} hrefLang="sk">
          <h3>{props.clanok.title}</h3>
        </Link>
        <div dangerouslySetInnerHTML={{ __html: props.clanok.excerpt }}></div>
        <Link to={"/blog/" + url} hrefLang="sk" className="readMore">
          Čítať viac
          <StaticImage
            className="img"
            src="../../images/new-web/arrow.svg"
            alt="Šípka vpravo"
            placeholder="blurred"
          />
        </Link>
      </div>
    </div>
  );
};

export default BlogClankyItem;

import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import MainSectionPripadoveStudie from "../components/pripadove-studie/mainSection";

import "../styles/blog.scss";
import BlogClanky from "../components/blog/blogClanky";
import ContactUs from "../components/contactUs";

export const pageQuery = graphql`
  query {
    allWpCategory(filter: { slug: { eq: "pripadove-studie" } }) {
      nodes {
        name
        slug
        count
      }
    }
    wpCategory(slug: { eq: "pripadove-studie" }) {
      posts {
        nodes {
          title
          excerpt
          slug
          featuredImage {
            node {
              altText
              title
              gatsbyImage(width: 1920)
              sourceUrl
            }
          }
        }
      }
    }
  }
`;

const PripadoveStudie = (pageQuery) => (
  <Layout pageName="blog-main">
    <SEO
      title="Prípadové štúdie"
      description="Sledujeme pre vás najnovšie trendy a nasledujeme ich. Nenechajte si ujsť naše tipy, rady a dlhoročné skúsenosti, ktoré vám pomôžu pri tvorbe vášho webu."
    />
    <MainSectionPripadoveStudie />
    <BlogClanky
      heading="Naše prípadové štúdie"
      clanky={pageQuery.data.wpCategory.posts.nodes}
    />
    <ContactUs
      text={
        <>
          Máte záujem o podobný projekt?
          <br /> Ozvite sa nám!
        </>
      }
      button="Poraďte sa s nami hneď"
    />
  </Layout>
);

export default PripadoveStudie;

import React from "react";
import BlogClankyItem from "./blogClankyItem.js";

const BlogClanky = (props) => {
  /* console.log("Clanky debug: ");
   console.log(props.clanky); */

  const clankyVypis = props.clanky.map((node) => (
    <BlogClankyItem clanok={node} />
  ));

  return (
    <section id="blogs">
      {clankyVypis.length === 0 ? (
        <h2>V tejto kategórií sa nenachádzaju žiadne články</h2>
      ) : (
        <h2>{props.heading ? props.heading : "Najnovšie články"}</h2>
      )}
      {clankyVypis}
    </section>
  );
};

export default BlogClanky;

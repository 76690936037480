import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const MainSectionPripadoveStudie = () => {
  return (
    <section className="section-blog">
      <div className="banner-blog">
        <h1>Prípadové štúdie</h1>
        <h2>
          Nižšie nájdete naše prípadové štúdie, ktoré vám ukážu, ako sme pomohli
          našim klientom.&#128200;&#128077;
        </h2>
        <StaticImage
          className="arrow-down"
          src="../../images/new-web/arrow.svg"
          alt="Arrow"
          placeholder="blurred"
        />
      </div>
    </section>
  );
};

export default MainSectionPripadoveStudie;

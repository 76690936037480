import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import handshakeIcon from "../images/new-web/handshake.svg";

const ContactUs = (props) => {
  return (
    <section id="contact-us">
      {/*<StaticImage src="../images/new-web/handshake.svg" alt="Handshake" placeholder="tracedSVG" quality="60"/>*/}
      <img src={handshakeIcon} alt="Handshake" loading="lazy" />
      <h4>{props.text}</h4>
      <div className="contactWraper">
        <Link className="contact-button" to="/kontakt">
          {props.button}
        </Link>
        <div id="telCislo">
          <>
            <br />
            <span>{props.enText ? "or call" : "alebo zavolajte"}</span>
            <br />
          </>
          <a href="tel:+421902085900">+421 902 085 900</a>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
